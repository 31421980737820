<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :can-cancel="false" />
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
        <CCard>
            <CCardHeader>
                <CIcon name="cil-terrain"/><strong> Zones </strong>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    :key="zoneDataKey"
                    :items="zones"
                    :fields="fields"
                    :hover="hover" >
                <template #access="{item}">
                    <td>
                        <CBadge :color="$config.flagClr(item.flags)">{{ $config.flagTxt(item.flags) }}</CBadge>
                    </td>
                </template>
                <template #status="{item}">
                    <td>
                        <CBadge :color="$config.statusClr(active[item.sceneName])">{{ $config.statusTxt(active[item.sceneName]) }}</CBadge>
                    </td>
                </template>
                <template #run="{item}">
                    <td class="py-2">
                        <div  v-if="isDeployed">
                            <div v-if="active[item.sceneName] > 0">
                                {{ active[item.sceneName] }} Instances
                            </div>
                            <CButton v-else
                                        color="light"
                                        square
                                        size="sm"
                                        @click="startZone(item.sceneName)">
                                Start
                            </CButton>
                        </div>
                        <div v-else>
                            N/A
                        </div>
                    </td>
                </template>
                <template #manage="{item}">
                    <td class="py-2">
                        <CButton
                                color="primary"
                                variant="outline"
                                square
                                size="sm"
                                @click="manageZone(item.id)">
                            Manage
                        </CButton>
                    </td>
                </template>
            </CDataTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "Zones",
        components: {
            Loading
        },
        data() {
            return {
                zones: [],
                active: {},
                fields: [
                    { key: "zoneId", label: "ID" },
                    { key: 'sceneName', label: 'Scene' },
                    { key: 'displayName', label: 'Name' },
                    { key: 'worldId', label: 'World ID' },
                    { key: 'address', label: 'IP' },
                    { key: 'defaultPopulationThreshold', label: 'Default Pop' },
                    { key: 'access', label: '' },
                    { key: 'status', label: '' },
                    { key: 'run', label: '' },
                    { key: 'manage', label: '' },
                ],
                hover: true,
                isLoading : false,
                zoneDataKey: 0,
                isDeployed: false,
            }
        },
        computed: {
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        methods: {
            getZones() {
                this.zonesLoading = true;
                this.isLoading = true;
                this.active = {};
                this.$http.get(this.$config.gameapi + '/Zone/' + localStorage.env, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.zones = jsonData;
                    this.getActive();             
                }).catch(function (error) {
                    this.$root.$emit('onErr', error);
                });
            },
            getActive() {
                this.$http.get(this.$config.zc[localStorage.env] + '/' + localStorage.env + '/procs', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.isDeployed = jsonData.IsDeployed;
                    for (let i = 0; i < this.zones.length; i++) {
                        this.active[this.zones[i].sceneName] = 0;
                    }
                    for (let i = 0; i < jsonData.RunningZones.length; i++) {
                        this.active[jsonData.RunningZones[i].Zone]++;
                    }
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.zoneDataKey++;
                    this.isLoading = false;
                });
            },
            manageZone(id) {
                this.$router.push({ name: 'Zone', params: { id: id } })
            },
            startZone(scene) {
                this.isLoading = true;
                this.$http.post(this.$config.zc[localStorage.env] + '/' + localStorage.env + '/zones/start/' + scene + '?instance=0', null,{ headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    if (jsonData.status){
                        setTimeout(() => { this.getActive(); }, 2000);
                    } else {
                        this.isLoading = false;
                        this.$root.$emit('sendMsg', "API Error", "danger", "Unable to start Zone: " + jsonData.message );
                    }
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                });
            }
        },
        created() {
            this.getZones();
            window.onpopstate = function () {
                location.reload()
            };
        }
    }
</script>
